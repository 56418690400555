<style lang="less" scoped>
.group_box {
    display: flex;
    overflow: auto hidden;
    // padding: 10px 0;
    position: relative;
    z-index: 9;
    .list {
        flex: 1;
        width: 0;
        max-width: 150px;
        min-width: 128px;
        background-color: #fff;
        border-radius: 10px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        & + .list {
            margin-left: 20px;
        }
    }
}
.icon_box {
    background-image: url(https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/data_icon.png);
    width: 32px;
    height: 32px;
    background-size: 32px;
    margin-left: 10px;
}
</style>
<style scoped lang="less">
.chart_content {
    background-color: #fff;
    margin-top: 20px;
    .chart_hd {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
    }
    .select_date {
        padding: 0 10px;
    }
}
</style>
<template>
    <div>
        <div class="select_box">
            <div class="group_box">
                <div
                    class="list"
                    v-for="(item, index) in data_list"
                    :key="index"
                >
                    <div>{{ item.label }}</div>
                    <div
                        class="icon_box"
                        :style="{ backgroundPositionY: item.iconY }"
                    ></div>
                </div>
            </div>
        </div>
        <div class="chart_content">
            <div class="chart_hd">
                <div class="title">视频数据趋势 (暂只支持抖音)</div>
                <div>
                    <div class="seek_input">
                        <el-autocomplete
                            v-model="userNickname"
                            :fetch-suggestions="querySearchAsync"
                            placeholder="请输入内容"
                            @select="handleSelect"
                            size="mini"
                        >
                            <i
                                slot="prefix"
                                class="el-input__icon el-icon-search"
                            ></i
                        ></el-autocomplete>
                    </div>
                </div>
            </div>
            <div class="select_date">
                <el-date-picker
                    v-model="timer"
                    type="daterange"
                    align="right"
                    unlink-panels
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    size="small"
                    :picker-options="pickerOptions"
                    :default-time="['2023-06-01', '2023-06-10']"
                    @change="changeDate"
                >
                </el-date-picker>
            </div>
            <div
                class="chart_box"
                style="width: 100%; height: 500px"
                ref="chart_box"
            ></div>
        </div>
    </div>
</template>

<script>
let option = {
    color: false,
    title: false,
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "cross",
            label: {
                backgroundColor: "#6a7985",
            },
        },
    },
    legend: false,
    toolbox: false,
    grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
    },
    xAxis: [
        {
            type: "category",
            boundaryGap: false,
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
    ],
    yAxis: [
        {
            type: "value",
        },
    ],
    series: [
        {
            name: "Line 1",
            type: "line",
            stack: "Total",
            smooth: true,
            lineStyle: {
                width: 1,
                color: "#24E4EE",
            },
            showSymbol: false,
            areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                        offset: 0,
                        color: "#117EFF",
                    },
                    {
                        offset: 1,
                        color: "rgba(17,128,255,0)",
                    },
                ]),
            },
            emphasis: {
                focus: "series",
            },
            data: [140, 232, 101, 264, 90, 340, 250],
        },
    ],
};
import { getAuthList } from "@/api/auth";
import { getUserData, getUserDataList } from "@/api/index";
import moment from "moment";
import * as echarts from "echarts";
export default {
    name: "authData",

    data() {
        return {
            // 选择用户
            userNickname: "",
            // 选择时间
            timer: "",
            data_list: [
                {
                    label: "播放量",
                    value: "",
                    iconY: 0,
                },
                {
                    label: "点赞量",
                    value: "",
                    iconY: "-32px",
                },
                {
                    label: "评论数",
                    value: "",
                    iconY: "-64px",
                },
                {
                    label: "分享数",
                    value: "",
                    iconY: "-96px",
                },
                {
                    label: "粉丝数",
                    value: "",
                    iconY: "-128px",
                },
                {
                    label: "增粉数",
                    value: "",
                    iconY: "-160px",
                },
                {
                    label: "商家视频",
                    value: "",
                    iconY: "-192px",
                },
                {
                    label: "账户数",
                    value: "",
                    iconY: "-224px",
                },
                {
                    label: "可用素材",
                    value: "",
                    iconY: "-256px",
                },
            ],
            user_where: {
                platformType: "douyin",

                size: 10,
                current: 1,
            },
            auth_list: [],
            // 日期快速选择
            pickerOptions: {
                shortcuts: [
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 30
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 90
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
            // 获取线型图的请求参数
            getLineWhere: {
                authorizedUserId: "",
                dataType: 1,
                endTime: "",
                startTime: "",
            },
        };
    },

    mounted() {
        // console.log(moment().subtract(7, "days").valueOf());
        let chart_box = this.$refs.chart_box;
        var myChart = echarts.init(chart_box);
        var xdata = [];
        myChart.setOption(option);
        // for (let i = 0; i < 30; i++) {
        //     // let days = -i + "day";
        //     let day = moment().subtract(i, "days").format("YYYY-MM-DD");
        //     xdata.push(day)
        // }
        // 获取授权用户数据
        // getAuthList(this.where).then((res) => {
        //     if (res.code == 200) {
        //         this.auth_list = res.data.records;
        //         let uid = res.data.records[0].id;
        //         this.getLineWhere.authorizedUserId = uid;
        //     }
        // });
        // getUserData().then((res) => {
        //     console.log(res);
        // });
        // 获取单项历史数据
        // this.getLineWhere.startTime = moment().valueOf();
        // this.getLineWhere.endTime = moment().subtract(7, "days").valueOf();
        // getUserDataList(this.getLineWhere).then((res) => {
        //     console.log(res);
        // });
    },

    methods: {
        querySearchAsync(queryString, cb) {
            let where = JSON.stringify(this.user_where);
            where = JSON.parse(where);
            if (this.userNickname) {
                where["userNickname"] = this.userNickname;
            }
            getAuthList(where).then((res) => {
                if (res.code == 200) {
                    this.auth_list = res.data.records;
                    this.auth_list.forEach((item) => {
                        item.value = item.userNickname;
                    });
                    cb(this.auth_list);
                }
            });
        },
        handleSelect(item) {
            console.log(item);
        },
        changeDate(val) {
            let starTime = Date.parse(val[0]);
            let endTime = Date.parse(val[1]);
            let where = this.getLineWhere;
            where.startTime = starTime;
            where.endTime = endTime;
            console.log(starTime);
            console.log(endTime);
            getUserDataList(where).then((res) => {
                console.log(res);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
</style>