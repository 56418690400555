<style lang="less" scoped>
.banner_list {
    height: 100%;
    // background-image: url(https://hotkeypic.oss-cn-shanghai.aliyuncs.com/test/banner.jpg);
    background-position: center;
    background-size: cover;
    .banner_main {
        height: 100%;
        width: 100%;
        // max-width: 1400px;
        min-width: 900px;
        // padding: 0 100px;
        box-sizing: border-box;
        margin: 0 auto;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.banner_left {
    color: #fff;
    .tiitle {
        margin-top: 10px;
        font-size: 34px;
    }
    .p {
        margin-top: 14px;
        font-size: 18px;
    }
    .tis {
        font-size: 12px;
        margin-top: 4px;
    }
}
.banner_right {
    position: relative;
    width: 320px;
    line-height: 0;
    margin-right: 50px;
    .small_img {
        position: absolute;
        z-index: 9;
    }
    img {
        width: 100%;
    }
    .small_1 {
        position: relative;
        top: 0;
        left: 0;
        z-index: 89;
    }
    .small_2 {
        width: 160px;
        bottom: -42px;
        left: -13px;
    }
    .small_3 {
        width: 100px;
        top: 0;
        right: -72px;
    }
    .small_4 {
        top: 0;
        left: -50px;
        z-index: 99;
    }
    .small_5 {
        bottom: -29px;
        right: -55px;
        z-index: 99;
    }
    .small_6 {
        top: -71px;
        right: -30px;
        z-index: 79;
    }
}
</style>
<template>
    <div class="banner">
        <el-carousel
            :interval="5000"
            height="450px"
            arrow="none"
            :autoplay="true"
            :loop="true"
        >
            <el-carousel-item
                v-for="(item, index) in banner"
                :key="index"
                :autoplay="true"
            >
                <div class="banner_list">
                    <div class="banner_main">
                        <el-image
                            style="width: 100%; height: 100%"
                            :src="item.coverUrl"
                            fit="cover"
                        ></el-image>
                        <!-- <div class="banner_left">
                            <h2 class="tiitle">高效裂变 轻松获客</h2>
                            <p class="p">提升短视频制作效率与效果</p>
                            <p class="tis">一键批量剪辑跟 “人效” 低说再见</p>
                        </div>
                        <div class="banner_right">
                            <div class="big_img small_1">
                                <img
                                    src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/banner/img1.png"
                                    alt=""
                                />
                            </div>
                            <div class="small_img small_2">
                                <img
                                    src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/banner/img2.png"
                                    alt=""
                                />
                            </div>
                            <div class="small_img small_3">
                                <img
                                    src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/banner/img5.png"
                                    alt=""
                                />
                            </div>
                            <div class="small_img small_4">
                                <img
                                    src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/banner/img4.png"
                                    alt=""
                                />
                            </div>
                            <div class="small_img small_5">
                                <img
                                    src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/banner/img3.png"
                                    alt=""
                                />
                            </div>
                            <div class="small_img small_6">
                                <img
                                    src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/banner/img6.png"
                                    alt=""
                                />
                            </div>
                        </div> -->
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
import { getBanner } from "@/api/index.js";
export default {
    name: "banner",

    data() {
        return {
            src: "https://hotkeypic.oss-cn-shanghai.aliyuncs.com/test/banner.jpg",
            banner: [],
        };
    },
    created() {
        let data = {
            directoryId: 1,
        };
        getBanner(data).then((res) => {
            this.banner = res.data.records;
        });
    },
    mounted() {},

    methods: {},
};
</script>

<style lang="less" scoped>
</style>