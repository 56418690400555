<style lang="less" scoped>
.about_box {
    padding: 80px 0;
    position: relative;
    display: flex;
    justify-content: center;
    // &::before {
    //     content: "";
    //     display: block;
    //     width: 200px;
    //     height: 400px;
    //     position: absolute;
    //     background-image: url(https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/guang_l.png);
    //     background-size: cover;
    //     left: 0;
    //     top: 0;
    // }
    // &::after {
    //     content: "";
    //     display: block;
    //     width: 472px;
    //     height: 454px;
    //     position: absolute;
    //     right: 0;
    //     bottom: -80px;
    //     background-image: url(https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/guang_r.png);
    //     background-size: 100% 100%;
    // }
    .about_list {
        text-align: center;
        flex: 0 0 400px;
        height: 400px;
        box-sizing: border-box;
        padding: 40px 30px;
        position: relative;
        background-color: #fff;
        z-index: 8;
        & + .about_list {
            margin-left: 46px;
        }
        img {
            width: 140px;
        }
        p {
            text-align: left;
            line-height: 1.3;
            letter-spacing: 2px;
            text-align: center;
        }
        h3 {
            margin-top: 10px;
            margin-bottom: 16px;
        }
        & > div {
            width: 180px;
            margin: 0 auto;
        }
    }
}
</style>
<template>
    <div class="about_box">
        <div class="about_list">
            <div>
                <img
                    src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/about/about_1.png"
                    alt=""
                />
            </div>
            <div>
                <h3>获客成本低</h3>
                <p>
                    借助中心化的抖音流量分发机制，用技术来进行矩阵布局更容易低成本获得精准流量。
                </p>
            </div>
        </div>
        <div class="about_list">
            <div>
                <img
                    src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/about/about_2.png"
                    alt=""
                />
            </div>
            <div>
                <h3>用户覆盖广</h3>
                <p>
                    抖音用户达8.09亿左右，DAU日活用户量级超7亿，已成为大众获取碎片化知识、线上购物、社交的主要阵地。
                </p>
            </div>
        </div>
        <div class="about_list">
            <div>
                <img
                    src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/index/about/about_3.png"
                    alt=""
                />
            </div>
            <div>
                <h3>转化更高效</h3>
                <p>
                    借助电商逻辑，短视频/直播做产品种草，更容易激发用户潜在需求，让成交更加短、平、快
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutSys",

    data() {
        return {};
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="scss" scoped>
</style>