<style lang="less" scoped>
.fans_content {
    background-color: #fff;
}
.fans_item {
}
.chart_hd {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}
.select_date {
    padding: 0 10px;
}
.fans_data {
    padding: 10px;
}
.card_box {
    background-color: #f6f8f9;
    padding: 0 20px;
    .card_hd {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        font-weight: bold;
        box-sizing: border-box;
        // padding: 0 10px;
    }
    .card_body {
        height: 282px;
    }
}
.not_data {
    background-color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 80px;
    }
}
.fans_row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin-top: 10px;
}
.sex_chart_box {
    width: 280px;
    height: 280px;
}
.sex_chart {
    display: flex;
}
.sex_li_box {
    .sex_li {
        display: flex;
    }
}
.li_item {
    display: flex;
    align-items: center;
    line-height: 1;
    margin-right: 10px;
    margin-right: 34px;
}
.li_color {
    display: block;
    width: 12px;
    height: 12px;
    margin-right: 4px;
}
.girl {
    background: linear-gradient(90deg, #b254ff 0%, #d9aaff 100%);
}
.boy {
    background: linear-gradient(270deg, #4574fd 0%, #85c3ff 100%);
}
.sex_li_box {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
}
.sex_tj_box {
    margin-top: 40px;
    .boy_tj {
        color: #4574fd;
        margin-bottom: 40px;
    }
    .girl_tj {
        color: #b254ff;
    }
    .boy_tj,
    .girl_tj {
        display: flex;
        align-items: center;
    }
    span + span {
        padding: 0 10px;
    }
}
.bai_box {
    background-color: #fff;
    height: 100%;
}
.map_chart {
    height: 100%;
}
.sort_box {
    height: 100%;
    display: flex;
    .sort_item {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0 10px;
    }
}
.sort_row {
    display: flex;
    justify-content: space-around;
    li {
        &:nth-child(1) {
            color: #4657fc;
        }
        &:nth-child(3) {
            color: #86909c;
        }
    }
}
</style>
<template>
    <div class="fans_content">
        <div class="fans_data">
            <div class="fans_row">
                <div class="fans_item">
                    <div class="card_box">
                        <div class="card_hd">
                            <span>性别分布</span>
                        </div>
                        <div class="card_body">
                            <div class="sex_chart">
                                <div
                                    class="sex_chart_box"
                                    id="chart_box"
                                    ref="chart_box"
                                ></div>
                                <div class="sex_li_box">
                                    <ul class="sex_li">
                                        <li class="li_item">
                                            <span class="li_color boy"></span>
                                            <span>男生</span>
                                        </li>
                                        <li class="li_item">
                                            <span class="li_color girl"></span>
                                            <span>女生</span>
                                        </li>
                                    </ul>
                                    <ul class="sex_tj_box">
                                        <li class="boy_tj">
                                            <span class="li_color boy"></span>
                                            <span>男生</span>
                                            <span>{{ sex.boy.num }}</span>
                                            <span
                                                >{{ sex.boy.percentage }}%</span
                                            >
                                        </li>
                                        <li class="girl_tj">
                                            <span class="li_color girl"></span>
                                            <span>女生</span>
                                            <span>{{ sex.girl.num }}</span>
                                            <span
                                                >{{
                                                    sex.girl.percentage
                                                }}%</span
                                            >
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- <div class="not_data" ref="not_data" v-else>
                                <img
                                    src="../../assets/images/not_data.png"
                                    alt=""
                                />
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="fans_item">
                    <div class="card_box">
                        <div class="card_hd">
                            <span>年龄分布</span>
                        </div>
                        <div class="card_body">
                            <div
                                class=""
                                id="chart_line"
                                style="height: 100%"
                            ></div>

                            <div class="not_data" ref="not_data">
                                <img
                                    src="../../assets/images/not_data.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fans_row" style="background-color: #f6f8f9">
                <div class="fans_item">
                    <div class="card_box">
                        <div class="card_hd">
                            <span>地域分布</span>
                        </div>
                        <div class="card_body">
                            <div class="map_chart" id="map_chart"></div>
                        </div>
                    </div>
                    <!-- <div class="not_data" ref="not_data" v-show="!isData[2]">
                        <img src="../../assets/images/not_data.png" alt="" />
                    </div> -->
                </div>
                <div class="fans_item">
                    <div class="card_box">
                        <div class="card_hd">
                            <span>地区排名</span>
                        </div>
                        <div class="card_body">
                            <div class="sort_box">
                                <div class="sort_item">
                                    <div
                                        v-for="i in 5"
                                        :key="i"
                                        class="sort_list"
                                    >
                                        <ul class="sort_row">
                                            <li>No.{{ i }}</li>
                                            <li>{{ map_data[i - 1].name }}</li>
                                            <li>
                                                {{ map_data[i - 1].value }} 人
                                            </li>
                                            <li>
                                                {{
                                                    (
                                                        (map_data[i - 1].value /
                                                            mapCount) *
                                                        100
                                                    ).toFixed(2)
                                                }}%
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="sort_item">
                                    <div
                                        v-for="i in 5"
                                        :key="i"
                                        class="sort_list"
                                    >
                                        <ul class="sort_row">
                                            <li>No.{{ i + 5 }}</li>
                                            <li>{{ map_data[i + 4].name }}</li>
                                            <li>
                                                {{ map_data[i + 4].value }} 人
                                            </li>
                                            <li>
                                                {{
                                                    (
                                                        (map_data[i + 4].value /
                                                            mapCount) *
                                                        100
                                                    ).toFixed(2)
                                                }}%
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <!-- <div
                                class="not_data"
                                ref="not_data"
                                v-show="!isData[3]"
                            >
                                <img
                                    src="../../assets/images/not_data.png"
                                    alt=""
                                />
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="fans_row">
                <div class="fans_item">
                    <div class="card_box">
                        <div class="card_hd">
                            <span>设备分布</span>
                        </div>
                        <div class="card_body">
                            <div
                                class="bing"
                                style="height: 100%"
                                id="bing_chart"
                            ></div>
                            <!-- <div
                                class="not_data"
                                ref="not_data"
                                v-show="!isData[4]"
                            >
                                <img
                                    src="../../assets/images/not_data.png"
                                    alt=""
                                />
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="fans_item">
                    <div class="card_box">
                        <div class="card_hd">
                            <span>粉丝兴趣分布图</span>
                        </div>
                        <div class="card_body">
                            <div
                                class="chu"
                                style="height: 100%"
                                id="chu_chart"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getFanportrait } from "@/api/index";

import chinaMap from "@/assets/china.json";
echarts.registerMap("china", { geoJSON: chinaMap });
import * as echarts from "echarts";
let res = {
    code: 200,
    msg: "Success",
    data: {
        //设备分布
        deviceDistributions: {
            摩托罗拉: 6,
            VIVO: 1232,
            鼎桥: 8,
            OPPO: 1268,
            金立: 2,
            坚果: 4,
            锤子科技: 2,
            小米: 604,
            联想: 8,
            "HI NOVA": 24,
            苹果: 5750,
            联通: 6,
            真我: 38,
            红米: 112,
            中国移动: 16,
            黑鲨: 8,
            华为: 2104,
            一加: 110,
            IQOO: 100,
            其他: 2,
            荣耀: 690,
            REALME: 46,
            努比亚: 10,
            酷派: 4,
            天翼: 4,
            魅族: 16,
            UNKNOWN: 360,
            乐视: 8,
            华为荣耀: 292,
            锤子: 6,
            三星: 112,
        },
        // 性别分布
        genderDistributions: {
            1: 10308,
            2: 2630,
        },
        // 粉丝活跃天数分布
        activeDaysDistributions: {
            "0~4": 2602,
            "13~16": 236,
            "17~20": 274,
            "9~12": 236,
            "20+": 9322,
            "5~8": 288,
        },
        // 所有粉丝的数量
        allFansNum: 0,
        // 粉丝年龄分布
        ageDistributions: {
            "24-30": 4594,
            "50-": 448,
            "41-50": 1256,
            "1-23": 1702,
            "31-40": 4938,
        },
        // 粉丝地域分布
        geographicalDistributions: {
            山东: 564,
            福建: 604,
            河南: 634,
            河北: 482,
            重庆: 204,
            湖北: 494,
            湖南: 372,
            海南: 106,
            江西: 334,
            黑龙江: 240,
            天津: 158,
            陕西: 340,
            贵州: 296,
            新疆: 390,
            澳门: 2,
            江苏: 974,
            安徽: 444,
            西藏: 34,
            吉林: 176,
            上海: 320,
            香港: 14,
            山西: 358,
            甘肃: 158,
            宁夏: 70,
            四川: 538,
            广西: 252,
            浙江: 1380,
            云南: 290,
            内蒙古: 226,
            辽宁: 390,
            广东: 1832,
            青海: 48,
            北京: 138,
        },
        // 粉丝兴趣分布
        interestDistributions: {
            时政社会: 2696,
            美食: 3002,
            汽车: 1176,
            剧情演绎: 1302,
            亲子: 3068,
            时尚: 2436,
            影视: 2702,
            个人管理: 2384,
            随拍: 12502,
            音乐: 1080,
        },
    },
};
export default {
    name: "FansData",
    props: {
        fans_data: {
            default: "",
        },
    },
    data() {
        return {
            //性别数据
            sex: {
                count: 0,
                boy: {
                    num: 0,
                    percentage: 0,
                },
                girl: {
                    num: 0,
                    percentage: 0,
                },
            },
            map_data: [
                {
                    name: "北京市",
                    value: 0,
                },
                {
                    name: "天津市",
                    value: 0,
                },
                {
                    name: "上海市",
                    value: 0,
                },
                {
                    name: "重庆市",
                    value: 0,
                },
                {
                    name: "河北省",
                    value: 0,
                },
                {
                    name: "河南省",
                    value: 0,
                },
                {
                    name: "云南省",
                    value: 0,
                },
                {
                    name: "辽宁省",
                    value: 0,
                },
                {
                    name: "黑龙江省",
                    value: 0,
                },
                {
                    name: "湖南省",
                    value: 0,
                },
                {
                    name: "安徽省",
                    value: 0,
                },
                {
                    name: "山东省",
                    value: 0,
                },
                {
                    name: "新疆维吾尔自治区",
                    value: 0,
                },
                {
                    name: "江苏省",
                    value: 0,
                },
                {
                    name: "浙江省",
                    value: 0,
                },
                {
                    name: "江西省",
                    value: 0,
                },
                {
                    name: "湖北省",
                    value: 0,
                },
                {
                    name: "广西壮族自治区",
                    value: 0,
                },
                {
                    name: "甘肃省",
                    value: 0,
                },
                {
                    name: "山西省",
                    value: 0,
                },
                {
                    name: "内蒙古自治区",
                    value: 0,
                },
                {
                    name: "陕西省",
                    value: 0,
                },
                {
                    name: "吉林省",
                    value: 0,
                },
                {
                    name: "福建省",
                    value: 0,
                },
                {
                    name: "贵州省",
                    value: 0,
                },
                {
                    name: "广东省",
                    value: 0,
                },
                {
                    name: "青海省",
                    value: 0,
                },
                {
                    name: "西藏自治区",
                    value: 0,
                },
                {
                    name: "四川省",
                    value: 0,
                },
                {
                    name: "宁夏回族自治区",
                    value: 0,
                },
                {
                    name: "海南省",
                    value: 0,
                },
                {
                    name: "台湾省",
                    value: 0,
                },
                {
                    name: "香港特别行政区",
                    value: 0,
                },
                {
                    name: "澳门特别行政区",
                    value: 0,
                },
                {
                    name: "南海诸岛",
                    value: 0,
                },
            ],
            mapCount: 0,
            isData: [0, 0, 0, 0, 0],
        };
    },
    created() {},
    mounted() {
        this.getUserData();
    },
    watch: {
        fans_data(val) {
            console.log(val);
            this.getUserData(val);
        },
    },

    methods: {
        // 获取粉丝画板数据
        async getUserData(data) {
            // if (!this.fans_data) {
            //     return;
            // }
            // let data = data;
            // console.log(data);
            this.genderChart(data.genderDistributions);
            this.ageChart(data.ageDistributions);
            this.areaChart(data.geographicalDistributions);
            this.deviceChart(data.deviceDistributions);
            this.interestChart(data.interestDistributions);

            // let body =
            // console.log(res.data.genderDistributions["1"]);
        },
        // 性别数据
        genderChart(data) {
            var chart_box = document.getElementById("chart_box");
            if (!data) {
                chart_box.parentNode.innerHTML =
                    '<div style="height: 200px;width: 100%;display: flex;align-items: center;justify-content: center;">暂无数据</div>';
                return;
            }

            this.sex.boy.num = data["1"];
            this.sex.girl.num = data["2"];
            this.sex.count = data["1"] + data["2"];
            let boy_zan = (this.sex.boy.num / this.sex.count) * 1000;
            // console.log();
            this.sex.boy.percentage = Math.floor(boy_zan) / 10;
            let girl_zan = (100 - this.sex.boy.percentage) * 10;
            this.sex.girl.percentage = Math.floor(girl_zan) / 10;

            let sex_data = {
                tooltip: {
                    trigger: "item",
                },
                // color: ["#000", "#fff"],
                // legend: {
                //     top: "5%",
                //     left: "center",
                // },
                series: [
                    {
                        name: "Access From",
                        type: "pie",
                        // radius: [
                        //     this.sex.boy.percentage,
                        //     this.sex.girl.percentage,
                        // ],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: "center",
                        },
                        color: [
                            {
                                x: 1,
                                y: 0,
                                x2: 1,
                                y2: 1,
                                type: "linear",
                                global: false,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "#D9AAFF",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "#B254FF",
                                    },
                                    {
                                        offset: 1,
                                        color: "#D9AAFF",
                                    },
                                ],
                            },
                            {
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                type: "linear",
                                global: false,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "#85C3FF",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "#4574FD",
                                    },
                                    {
                                        offset: 1,
                                        color: "#85C3FF",
                                    },
                                ],
                            },
                        ],
                        itemStyle: {
                            borderColor: "#f6f8f9",
                            borderWidth: 4,
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 40,
                                fontWeight: "bold",
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            { value: this.sex.boy.num, name: "男" },
                            { value: this.sex.girl.num, name: "女" },
                        ],
                    },
                ],
            };

            // let chart_box = this.$refs.chart_box;
            // console.log(chart_box);

            var myChart = echarts.init(chart_box);
            myChart.setOption(sex_data);
        },
        // 年龄数据
        ageChart(data) {
            console.log(data);
            var age_chart = document.getElementById("chart_line");
            if (!data) {
                age_chart.parentNode.innerHTML =
                    '<div style="height: 200px;width: 100%;display: flex;align-items: center;justify-content: center;">暂无数据</div>';
                return;
            }
            var newkey = Object.keys(data).sort();
            // console.log(newkey);
            let age_keys = [];
            let ages = [];
            newkey.map((key) => {
                age_keys.push(key + "岁");
                ages.push(data[key]);
            });
            let age_data = {
                xAxis: {
                    type: "category",
                    data: age_keys,
                },
                yAxis: {
                    type: "value",
                },
                series: [
                    {
                        data: ages,
                        type: "line",
                        smooth: true,
                    },
                ],
            };

            var ageChart = echarts.init(age_chart);
            ageChart.setOption(age_data);
        },
        // 地区数据
        areaChart(data) {
            var map_chart = document.getElementById("map_chart");
            if (!data) {
                map_chart.parentNode.innerHTML =
                    '<div style="height: 200px;width: 100%;display: flex;align-items: center;justify-content: center;">暂无数据</div>';
                return;
            }
            let mapCount = 0;
            for (const key in data) {
                // console.log(data[key]);
                mapCount += data[key];
                let index = this.map_data.findIndex(
                    (item) => item.name.indexOf(key) !== -1
                );
                if (index !== -1) {
                    this.map_data[index].value = data[key];
                }
            }
            this.mapCount = mapCount;
            let mapArr = this.map_data;
            for (var i = 0; i < mapArr.length - 1; i++) {
                let percentage = (mapArr[i].value / mapCount) * 1000;
                // mapArr[i].percentage = Math.floor(percentage / 10);
                // console.log(mapArr[i]);
                // mapArr[i].percentage = (mapArr[i].value / mapCount) * 100;
                // console.log();
                for (var j = 0; j < mapArr.length - 1 - i; j++) {
                    if (mapArr[j].value < mapArr[j + 1].value) {
                        var temp = mapArr[j];
                        mapArr[j] = mapArr[j + 1];
                        mapArr[j + 1] = temp;
                    }
                }
            }
            let map_data = {
                title: {
                    text: "",
                    subtext: "",
                    color: "#fff",
                    x: "center",
                },
                //是视觉映射组件，用于进行『视觉编码』，也就是将数据映射到视觉元素（视觉通道）。
                visualMap: {
                    min: 0,
                    max: this.map_data[0].value || 1000,
                    text: ["High", "Low"],
                    realtime: false,
                    calculable: true,
                    inRange: {
                        color: ["lightskyblue", "yellow", "orangered"],
                    },
                },
                // 提示框，鼠标移入
                tooltip: {
                    show: true, //鼠标移入是否触发数据
                    trigger: "item", //出发方式
                    formatter: "{b}-用户数量：{c}",
                },
                //配置地图的数据，并且显示
                series: [
                    {
                        name: "地图",
                        type: "map", //地图种类
                        map: "china", //地图类型。
                        data: this.map_data,
                    },
                ],
            };
            var mapChart = echarts.init(map_chart);
            mapChart.setOption(map_data);
        },
        // 设备数据
        deviceChart(data) {
            var bing_chart = document.getElementById("bing_chart");
            if (!data) {
                bing_chart.parentNode.innerHTML =
                    '<div style="height: 200px;width: 100%;display: flex;align-items: center;justify-content: center;">暂无数据</div>';
                return;
            }
            let devices = [];
            for (const key in data) {
                if (data.hasOwnProperty.call(data, key)) {
                    let device = {
                        value: data[key],
                        name: key,
                    };
                    devices.push(device);
                }
            }
            let bing_data = {
                tooltip: {
                    trigger: "item",
                },
                series: [
                    {
                        type: "pie",
                        radius: "50%",
                        data: devices,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };

            var maoChart = echarts.init(bing_chart);
            maoChart.setOption(bing_data);
        },
        // 兴趣数据
        interestChart(data) {
            // console.log(data);

            var chartDom = document.getElementById("chu_chart");
            if (!data) {
                chartDom.parentNode.innerHTML =
                    '<div style="height: 200px;width: 100%;display: flex;align-items: center;justify-content: center;">暂无数据</div>';
                return;
            }
            let names = [];
            let nums = [];
            for (const key in data) {
                if (Object.hasOwnProperty.call(data, key)) {
                    const element = data[key];
                    names.push(key);
                    nums.push(data[key]);
                }
            }
            let option = {
                xAxis: {
                    type: "category",
                    data: names,
                },
                yAxis: {
                    type: "value",
                },
                tooltip: {
                    // trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            backgroundColor: "#6a7985",
                        },
                    },
                },
                series: [
                    {
                        data: nums,
                        type: "bar",
                        showBackground: true,
                        // backgroundStyle: {
                        //     color: "rgba(180, 180, 180, 0.2)",
                        // },
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    { offset: 0, color: "#83bff6" },
                                    { offset: 0.5, color: "#188df0" },
                                    { offset: 1, color: "#188df0" },
                                ]
                            ),
                        },
                        emphasis: {
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        { offset: 0, color: "#2378f7" },
                                        { offset: 0.7, color: "#2378f7" },
                                        { offset: 1, color: "#83bff6" },
                                    ]
                                ),
                            },
                        },
                    },
                ],
            };
            var myChart = echarts.init(chartDom);
            myChart.setOption(option);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>