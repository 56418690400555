import request from '@/utils/request'
// 获得总数据
export function getUserData(params) {
    return request({
        url: '/api/app/AccountDailyAdd/getAccountUserGroupData',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'GET',
        params
    })
}
// 获得单项数据
export function getUserDataList(params) {
    return request({
        url: '/api/app/AccountDailyAdd/getAccountUserDaliyAddList',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'GET',
        params
    })
}
// 获得单项数据
export function reservation(data) {
    return request({
        url: '/api/app/reservation',
        method: 'POST',
        data
    })
}
// 获得单项数据
export function getFanportrait(params) {
    return request({
        url: '/api/app/fanportrait/getFanportrait',
        method: 'GET',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params
    })
}
// 播放量，点赞数等数据汇总
export function getAuthorizedUsersCount(params) {
    return request({
        url: '/api/app/AccountVideo/authorizedUserSummaryInfo',
        method: 'GET',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params
    })
}

// 播放量，点赞数等数据汇总
export function getBanner(params) {
    return request({
        url: '/api/app/PcwebPictureFile',
        method: 'GET',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params
    })
}
